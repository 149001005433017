import React from 'react';
import Helmet from 'react-helmet';
import { Route, Switch, withRouter } from 'react-router-dom';
import './App.css';
import Home from './routes/home';

type Props = {
  location: Location;
};

function App() {
  return (
    <React.Fragment>
      <Helmet defaultTitle="TwoBirds" titleTemplate="%s - TwoBirds" />

      <Switch>
        <Route path="/verdvisir/view/:id" exact component={Home} />
        <Route path="/">
        {() => {
          window.location.href = "https://twobirds.io/";
        }}
        </Route>
      </Switch>
    </React.Fragment>
  );
}
export default withRouter(App);
