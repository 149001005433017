import React from "react";
import kindergarten from "../images/kindergarten.png";
import highschool from "../images/highschool.png";
import grocery from "../images/grocery.png";
import busstops from "../images/busstops.png";

interface data {
  nafn: string;
  texti: string;
}

interface dist {
  busstop_distance: number;
  busstop_duration: number;
  busstop_duration_minutes: number;
  createdAt: string;
  elementary_distance: number;
  elementary_duration: number;
  elementary_duration_minutes: number;
  fuel_distance: number;
  fuel_duration: number;
  fuel_duration_minutes: number;
  grocery_distance: number;
  grocery_duration: number;
  grocery_duration_minutes: number;
  highschool_distance: number;
  highschool_duration: number;
  highschool_duration_minutes: number;
  kindergarten_distance: number;
  kindergarten_duration: number;
  kindergarten_duration_minutes: number;
  mall_distance: number;
  mall_duration: number;
  mall_duration_minutes: number;
  pool_distance: number;
  pool_duration: number;
  pool_duration_minutes: number;
  type: string;
  updatedAt: string;
}

export default function Distance(props: {
  data: data;
  dist: Array<dist>;
  zip: number | undefined;
  location: string | undefined;
}) {
  const { data, dist, zip, location } = props;
  const code = dist.map((i, key) => {
    return (
      <div className={`distance-content ${i.type}_content`} key={key}>
        {i.kindergarten_duration_minutes ? (
          <div className="distance-pics-single">
            <img
              className="distance-pic"
              src={kindergarten}
              alt="kindergarten logo"
            />

            <div className="distance-pics-single-text">
              <p className="distance-title">Leikskóli</p>
              <p className="distance-time ">
                {i.kindergarten_duration_minutes + " mín"}
              </p>
            </div>
          </div>
        ) : null}
        {i.elementary_duration_minutes ? (
          <div className="distance-pics-single">
            <img
              className="distance-pic"
              src={highschool}
              alt="highschool logo"
            />
            <div className="distance-pics-single-text">
              <p className="distance-title">Grunnskóli</p>
              <p className="distance-time">
                {i.elementary_duration_minutes + " mín"}
              </p>
            </div>
          </div>
        ) : null}
        {i.grocery_duration_minutes ? (
          <div className="distance-pics-single">
            <img className="distance-pic" src={grocery} alt="grocery logo" />
            <div className="distance-pics-single-text">
              <p className="distance-title">Matvöruverslun</p>
              <p className="distance-time">
                {i.grocery_duration_minutes + " mín"}
              </p>
            </div>
          </div>
        ) : null}
        {i.highschool_duration_minutes ? (
          <div className="distance-pics-single">
            <img className="distance-pic" src={busstops} alt="busstops logo" />
            <div className="distance-pics-single-text">
              <p className="distance-title">Strætó</p>
              <p className="distance-time">
                {i.busstop_duration_minutes + " mín"}
              </p>
            </div>
          </div>
        ) : null}
      </div>
    );
  });

  return (
    <React.Fragment>
      <p className="Banner-title">Fjarlægðir</p>
      <div className="App-banner">
        <div className="eign-distance">
          <p className="Banner-title">
            {data.nafn} , {zip} {location}
          </p>
          <p className="small-text">{data.texti}</p>
          <div className="distance-pics">
            <input
              type="radio"
              id="driving_dist"
              name="dist_tabs"
              defaultChecked
            />
            <input type="radio" id="walking_dist" name="dist_tabs" />
            <input type="radio" id="bicycling_dist" name="dist_tabs" />
            <nav className="distance-navbar">{dist.map((i,key) => (
              <label
              key={key}
              htmlFor={`${i.type}_dist`}
              className="nav-link-eign"
              id={`${i.type}_label`}
            >
              {i.type === "driving"
                ? "Akandi"
                : i.type === "walking"
                ? "Gangandi"
                : "Hjólandi"}
            </label>
            ))}</nav>
          {code}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
