import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  XAxis,
  Legend,
  Bar
} from "recharts";

const COLORS = ["#5D914D", "#FFA611", "#30699E", "#333333"];

export default function Charts(props: any) {
  const { data } = props;
  const lol = data.tegundir.map((i: any) => {
    return { name: i.type, value: i.value };
  });
  const aldur = data.aldur;
  const maxVal = aldur.reduce(function (prev: any, curr: any) {
    return {
      Höfuðborgarsvæðið: prev.Höfuðborgarsvæðið + curr.Höfuðborgarsvæðið,
      Póstnúmer: prev.Póstnúmer + curr.Póstnúmer,
    };
  });
  const NewAldur = aldur.map((i: any) => {
    return {
      name: i.name,
      Höfuðborgarsvæðið: Math.round(
        (i.Höfuðborgarsvæðið / maxVal.Höfuðborgarsvæðið) * 100
      ),
      Póstnúmer: Math.round((i.Póstnúmer / maxVal.Póstnúmer) * 100),
    };
  });
  NewAldur.reverse();
  let renderLabel = function (entry: any) {
    return entry.name;
  };

  const piedata = (
    <PieChart>
      <Pie
        dataKey="value"
        data={lol}
        cx="50%"
        cy="50%"
        labelLine={true}
        label={renderLabel}
        outerRadius={"40%"}
        fill="#8884d8"
        isAnimationActive={true}
      >
        {lol.map((entry: any, index: any) => (
          <Cell key={index} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip />
    </PieChart>
  );

  const chartdata = (
    <BarChart data={NewAldur}>
      <XAxis dataKey="name" />
      <Tooltip />
      <Legend />
      <Bar dataKey="Póstnúmer" fill="#e0a233" isAnimationActive={false} />
      <Bar
        dataKey="Höfuðborgarsvæðið"
        fill="#97a1ae"
        isAnimationActive={false}
      />
    </BarChart>
  );

  const together = (
    <React.Fragment>
      <div className="nav">
        <div className="both-charts-text">
          <p className="Banner-title-big">Tegundir eigna í hverfi</p>
          <p className="Banner-title-big">Aldursdreifing í póstnúmeri</p>
        </div>
      </div>
      <div className="both-charts">
        <ResponsiveContainer width="50%" height={400}>
          {piedata}
        </ResponsiveContainer>

        <ResponsiveContainer width="50%" height={400}>
          {chartdata}
        </ResponsiveContainer>
      </div>
    </React.Fragment>
  );

  const test = (
    <div>
      <input type="radio" name="chartTabs" id="chartTab1" defaultChecked />
      <input type="radio" name="chartTabs" id="chartTab2" />
      <nav className="charts-navbar">

        <label className="nav-link-eign" htmlFor="chartTab1" id="tegundir_label">
        Tegundir eigna í hverfi
      </label>
      <label className="nav-link-eign" htmlFor="chartTab2" id="aldur_label">
        Aldursdreifing í póstnúmeri
      </label>
      </nav>
      <div className="chartTab content1">
        <ResponsiveContainer width="100%" height={400}>
          {piedata}
        </ResponsiveContainer>
      </div>
      <div className="chartTab content2">
        <ResponsiveContainer width="100%" height={400}>
          {chartdata}
        </ResponsiveContainer>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <div className="App-banner">
        <div className="nav-link">
          <div className="mobile">{test}</div>
          <div className="desktop">{together}</div>
        </div>
      </div>
    </React.Fragment>
  );
}
