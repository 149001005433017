import React, { useState, useEffect } from "react";

import Banner from "../elem/banner";
import Sold from "../elem/sold";
import OnSale from "../elem/onSale";
import { House, SoldProp, onSaleProp } from "../api/interfaces";
import {
  getHouse,
  getSold,
  getOnSale,
  getNeighbor,
  getDistance,
} from "../api";
import Charts from "../elem/charts";
import Distance from "../elem/distance";
import LoadingScreen from "../elem/loading";
import Header from "../elem/header";
import { isNull } from "util";

export default function Home(props: any) {
  const { id } = props.match.params;
  const [loading, setLoading] = useState(true);
  const [eign, seteign] = useState<any | null>();
  const [neighbor, setNeighbor] = useState<any | null>();
  const [sold_rad, setSoldRad] = useState<Array<SoldProp> | null>([]);
  const [sold_hve, setSoldHve] = useState<Array<SoldProp> | null>([]);
  const [sold_zip, setSoldZip] = useState<Array<SoldProp> | null>([]);
  const [onSale_rad, setOnSaleRad] = useState<Array<onSaleProp> | null>([]);
  const [onSale_hve, setOnSaleHve] = useState<Array<onSaleProp> | null>([]);
  const [onSale_zip, setOnSaleZip] = useState<Array<onSaleProp> | null>([]);
  const [distance, setDistance] = useState<any>(null);
  const [fail, setFail] = useState<Boolean>(false);
  async function getData() {
    setLoading(true);
    const data = await Promise.all([
      getHouse(id),
      getNeighbor(id),
      getDistance(id),
      getSold(id, "radius"),
      getSold(id, "hverfi"),
      getSold(id, "postnumer"),
      getOnSale(id, "radius"),
      getOnSale(id, "hverfi"),
      getOnSale(id, "postnumer"),
    ]);
    var tempFail = false;
    for (var i = 0; i < data.length; i++) {
      if (data[i] === null) {
        setFail(true);
        tempFail = true;
      }
    }
    if (!tempFail) {
      const [
        house,
        tempNeighbor,
        tempDist,
        temp_sold_rad,
        temp_sold_hve,
        temp_sold_zip,
        temponSale_rad,
        temponSale_hve,
        temponSale_zip,
      ] = data;
      setSoldRad(temp_sold_rad);
      setSoldHve(temp_sold_hve);
      setSoldZip(temp_sold_zip);
      setOnSaleRad(temponSale_rad);
      setOnSaleHve(temponSale_hve);
      setOnSaleZip(temponSale_zip);
      setNeighbor(tempNeighbor);
      seteign(house);
      setDistance(tempDist.length !== 0?tempDist:null);
    }
    setLoading(false);
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.Fragment>
      {!loading ? (
        <React.Fragment>
          <Header />
          {fail ? (
            <div className="App-banner">
              <div className="nav-link">
                <div className="mobile desktop">
                  <h1 className="Banner-title">Úpps eitthvað fór úrskeiðis</h1>
                </div>
              </div>
            </div>
          ) : (
            <main>
              <h1 className="Address">
                {eign?.eign.address}, {eign.eign.zip} {eign?.eign.location}
              </h1>
              <h2 className="date">Skýrsla sótt {eign.eign.reportBought.toString('mmm')}</h2>
              <Banner eign={eign?.eign} />
              <Sold
                sold_hve={sold_hve}
                sold_rad={sold_rad}
                sold_zip={sold_zip}
                hverfi={neighbor.hverfi.nafn}
                zip={eign?.eign.zip}
              />
              <OnSale
                onSale_hve={onSale_hve}
                onSale_rad={onSale_rad}
                onSale_zip={onSale_zip}
                hverfi={neighbor.hverfi.nafn}
                zip={eign?.eign.zip}
              />
              <Charts data={neighbor} />
              {!isNull(distance) ? (
                <Distance data={neighbor.hverfi} dist={distance} zip={eign?.eign.zip} location={eign?.eign.location}/>
              ):null}
            </main>
          )}
        </React.Fragment>
      ) : (
        <LoadingScreen />
      )}
    </React.Fragment>
  );
}
