import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { SoldProp } from "../api/interfaces.js";

const nfObject = new Intl.NumberFormat("de-DE");
const columns = [
  {
    name: <p className="category">Heimilisfang</p>,
    selector: "address",
    sortable: true,
    grow: 2,
    cell: (row: any) => (
      <div>
        {row.address}
      </div>
    )
  },
  {
    name: <p className="category">Seld</p>,
    selector: "sold",
    sortable: true,
    cell: (row: any) => (
      <div>
        <div>{row.sold}</div>
      </div>
    )
  },
  {
    name: <p className="category">Stærð</p>,
    selector: "size",
    sortable: true,
    cell: (row: any) => (
      <div>
        <div>
          {row.size} m<i className="sup">2</i>
        </div>
      </div>
    )
  },
  {
    name: <p className="category">Ásett</p>,
    selector: "setPriceMax",
    sortable: true,
    cell: (i: any) => (
      <div>
        <div>
          {i.setPriceMax
            ? i.setPriceMin
              ? (i.setPriceMax !== i.setPriceMin ? i.setPriceMax + "-" + i.setPriceMin: i.setPriceMax)
              : i.setPriceMax
            : "-"}
        </div>
      </div>
    )
  },
  {
    name: <p className="category">Kaupverð</p>,
    selector: "soldPrice",
    sortable: true,
    cell: (row: any) => (
      <div>
        <div>{row.soldPrice}</div>
      </div>
    )
  },
  {
    /* name: (
      <p>
        Verð per m<i className="sup">2</i>
      </p>
    ),
    selector: 'perM',
    sortable: true,
    grow: 1.3,
    cell: (row: any) => (
      <div>
        <div>
          {nfObject.format(Math.floor((row.soldPrice * 1000000) / row.size))}
        </div>
      </div>
    )
  }, */
    name: (
      <p className="category">
        Verð m<i className="sup">2</i>
      </p>
    ),
    selector: "pricePer",
    sortable: true,
    grow: 1.3,
    cell: (row: any) => (
      <div>
        <div>{row.pricePer ? nfObject.format(row.pricePer) : "-"}</div>
      </div>
    )
  },
  {
    name: <p className="category">Tegund</p>,
    selector: "type",
    sortable: true,
    cell: (row: any) => (
      <div>
        <div>{row.type}</div>
      </div>
    )
  },
  {
    name: <p className="category">Byggt</p>,
    selector: "year",
    sortable: true,
    cell: (row: any) => (
      <div>
        <div>{row.year}</div>
      </div>
    )
  },
  {
    name: <p className="category">Í sölu</p>,
    selector: "days",
    sortable: true,
    cell: (row: any) => (
      <div>
        <div>{row.days ? row.days + " daga" : "-"}</div>
      </div>
    )
  }
];

export default function Table(props: {
  sold_rad: SoldProp[] | null;
  sold_hve: SoldProp[] | null;
  sold_zip: SoldProp[] | null;
  zip?: number;
  hverfi?: string;
}) {
  const { sold_hve, sold_rad, sold_zip, hverfi, zip } = props;
  const [soldC, setSoldC] = useState<string>("radius");
  const [sold, setSold] = useState<Array<SoldProp> | null>(sold_rad);

  function changeSold(a: string) {
    if (soldC !== a) {
      setSoldC(a);
      if (a === "radius") {
        setSold(sold_rad);
      } else if (a === "hverfi") {
        setSold(sold_hve);
      } else {
        setSold(sold_zip);
      }
    }
  }
  return (
    <React.Fragment>
      <p className="Banner-title">
        Sambærilegar seldar eignir{" "}
        {soldC === "radius"
          ? "í nálægum radíus"
          : soldC === "hverfi"
          ? "í hverfinu (" + (hverfi ? hverfi : "-") + ")"
          : "í póstnúmeri (" + (zip ? zip : "-") + ")"}
      </p>
      <div className="Buttons-choice">
        <button
          className={
            soldC === "radius" ? "Button-choice toggled" : "Button-choice"
          }
          onClick={() => changeSold("radius")}
        >
          Radíus
        </button>
        <button
          className={
            soldC === "hverfi" ? "Button-choice toggled" : "Button-choice"
          }
          onClick={() => changeSold("hverfi")}
        >
          Hverfi
        </button>
        <button
          className={
            soldC === "postnumer" ? "Button-choice toggled" : "Button-choice"
          }
          onClick={() => changeSold("postnumer")}
        >
          Póstnúmer
        </button>
      </div>
      <div className="App-banner">
        {sold && <DataTable data={sold} columns={columns} noHeader={true} />}
      </div>
    </React.Fragment>
  );
}
